import { useState } from 'react';
import { useCookies } from 'react-cookie';
import LogIn from './LogIn';

export default function SignInLock(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['isLoggedIn', 'user']);
    const [isLoggedIn, setIsLoggedIn] = useState(cookies.isLoggedIn ? cookies.isLoggedIn : true);

    if (isLoggedIn) {
        return(
            props.children
        )
    } else {
        return(
            <LogIn />
        )
    }
}

