import { useState, useEffect, Fragment } from 'react';
import { Combobox, Transition, Switch } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

// export function Dropdown(props) {
//     return(
//         <div className={props.hidden ? 'hidden' : 'relative z-50 min-w-[13rem]'}>
//             <Combobox 
//                 value={props.selected}
//                 onChange={props.setSelected}
//                 disabled={props.disabled}
//             >
//                 <div className='relative mt-1'>
//                 <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
//                     <ChevronUpDownIcon
//                         className="h-5 w-5 text-gray-400"
//                         aria-hidden="true"
//                     />
//                 </Combobox.Button>
//                 <Transition
//                     as={Fragment}
//                     leave="transition ease-in duration-100"
//                     leaveFrom="opacity-100"
//                     leaveTo="opacity-0"
//                 >
//                     <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
//                     <Combobox.Option
//                         className={({ active }) =>
//                             `relative cursor-default select-none py-2 pl-10 pr-4 ${
//                             active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
//                             }`
//                         }
//                         value={`Select ${props.label}`}
//                         disabled
//                     >
//                         {({ selected }) => (
//                             <>
//                             <span
//                                 className={`block truncate ${
//                                 selected ? 'font-medium' : 'font-normal'
//                                 }`}
//                             >
//                                 {`Select ${props.label}`}
//                             </span>
//                             {selected ? (
//                                 <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
//                                 <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                                 </span>
//                             ) : null}
//                             </>
//                         )}
//                     </Combobox.Option>
//                     {props.options.map((option, optionIdx) => (
//                         <Combobox.Option
//                         key={optionIdx}
//                         className={({ active }) =>
//                             `relative cursor-default select-none py-2 pl-10 pr-4 ${
//                             active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
//                             }`
//                         }
//                         value={option}
//                         >
//                         {({ selected }) => (
//                             <>
//                             <span
//                                 className={`block truncate ${
//                                 selected ? 'font-medium' : 'font-normal'
//                                 }`}
//                             >
//                                 {option}
//                             </span>
//                             {selected ? (
//                                 <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
//                                 <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                                 </span>
//                             ) : null}
//                             </>
//                         )}
//                         </Combobox.Option>
//                     ))}
//                     </Combobox.Options>
//                 </Transition>
//                 </div>
//             </Combobox>
//         </div>
//     )
// }

export function PaginatedDropdown(props) {
    const [page, setPage] = useState(0);
    const [allOptions, setAllOptions] = useState([]);
    const [optionSets, setOptionSets] = useState([]);
    const [ready, setReady] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        function storeAllOptions() {
            if (props.options.length > 0) {
                setAllOptions([...new Set(props.options)]);
            } else {
                return;
            }
        };
        storeAllOptions();
        
    }, [props.options]);

    useEffect(() => {
        if (props.options.length > 0) {
            setReady(true);
            return getOptionSets();
        } else {
            return;
        }
    }, [allOptions, query]);
    function chunkArray(array, chunkSize) {
        return array.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize);
      
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
      
          resultArray[chunkIndex].push(item);
      
          return resultArray;
        }, []);
      }
    
    function getOptionSets() {
        if (query === '') {
            const chunkedOptions = chunkArray(allOptions, 8);
            return setOptionSets(chunkedOptions);
        } else {
            const filteredOptions = allOptions.filter(option => option.toLowerCase().includes(query.toLowerCase()));
            const chunkedOptions = chunkArray(filteredOptions, 8);
            return setOptionSets(chunkedOptions);
        };
    }

    function nextPage() {
        if (page < optionSets.length - 1) {
            var newPage = page + 1;
            return setPage(newPage);
        } else if (page === optionSets.length - 1) {
            return setPage(0);
        }
    }

    function prevPage() {
        if (page > 0) {
            var newPage = page - 1;
            return setPage(newPage);
        } else if (page === 0) {
            return setPage(optionSets.length - 1);
        }
    }

    function RenderOptions() {
        if (ready && optionSets.length > 0) {
            return optionSets[page].map((option, optionIdx) => (
                <Combobox.Option
                key={optionIdx}
                className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                }
                value={option}
                >
                {({ selected }) => (
                    <>
                    <span
                        className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                        }`}
                    >
                        {option}
                    </span>
                    {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    ) : null}
                    </>
                )}
                </Combobox.Option>
            ))
        } else {
            return null;
        }
    }

    return(
        <div className={props.hidden ? 'hidden' : 'relative z-50 min-w-[13rem] disabled:opacity-50 disabled:cursor-default'}>
            <div className='text-[10pt] text-[#CCCCCC] ml-2 text-left font-medium'>{props.label}:</div>
            <Combobox 
                value={props.selected}
                onChange={props.setSelected}
                disabled={props.disabled}
                className='w-[15rem] disabled:animate-pulse disabled:opacity-50 rounded-lg disabled:cursor-not-allowed '
            >
                <div className='relative mt-1 w-full'>
                <Combobox.Input
                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 rounded-lg"
                    displayValue={(item) => item}
                    onChange={(event) => {setQuery(event.target.value); setPage(0);}}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>
                {/* <Combobox.Button className="text-black relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate font-bold">{props.selected}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </Combobox.Button> */}
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Combobox.Options className="absolute mt-1 h-96 w-full overflow-auto rounded-md bg-white pt-1 text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    <div className='container m-0 p-0 h-[90%]'>
                    <Combobox.Option
                        className={({ active }) =>
                            `relative cursor-default font-bold select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            }`
                        }
                        value={`Select ${props.label}`}
                        disabled
                    >
                        {({ selected }) => (
                            <>
                            <span
                                className={`block truncate font-bold ${
                                selected ? '' : ''
                                }`}
                            >
                                {`Select ${props.label}`}
                            </span>
                            {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                            </>
                        )}
                    </Combobox.Option>
                    <RenderOptions />
                    </div>
                    <div className='grid grid-cols-3 m-0 p-0'>
                    <button className='w-full bg-[#ff8300] py-2 text-white rounded-r-xl disabled:opacity-50' onClick={() => prevPage()}>Back</button>
                    <span className='flex items-center justify-center text-gray-500'>{page+1}/{optionSets.length}</span>
                    <button className='w-full bg-[#ff8300] py-2 text-white rounded-l-xl disabled:opacity-50' onClick={() => nextPage()}>Next</button>
                    </div>
                    </Combobox.Options>
                    
                </Transition>
                </div>
            </Combobox>
        </div>
    )
};

export function Dropdown(props) {
    const [page, setPage] = useState(0);
    const [allOptions, setAllOptions] = useState([]);
    const [optionSets, setOptionSets] = useState([]);
    const [ready, setReady] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        function storeAllOptions() {
            if (props.options.length > 0) {
                setAllOptions([...new Set(props.options)]);
            } else {
                return;
            }
        };
        storeAllOptions();
        
    }, [props.options]);

    useEffect(() => {
        if (props.options.length > 0) {
            setReady(true);
            return getOptionSets();
        } else {
            return;
        }
    }, [allOptions, query]);
    function chunkArray(array, chunkSize) {
        return array.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize);
      
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
      
          resultArray[chunkIndex].push(item);
      
          return resultArray;
        }, []);
      }
    
    function getOptionSets() {
        if (query === '') {
            const chunkedOptions = chunkArray(allOptions, 8);
            return setOptionSets(chunkedOptions);
        } else {
            const filteredOptions = allOptions.filter(option => option.toLowerCase().includes(query.toLowerCase()));
            const chunkedOptions = chunkArray(filteredOptions, 8);
            return setOptionSets(chunkedOptions);
        };
    }

    function nextPage() {
        if (page < optionSets.length - 1) {
            var newPage = page + 1;
            return setPage(newPage);
        } else if (page === optionSets.length - 1) {
            return setPage(0);
        }
    }

    function prevPage() {
        if (page > 0) {
            var newPage = page - 1;
            return setPage(newPage);
        } else if (page === 0) {
            return setPage(optionSets.length - 1);
        }
    }

    function RenderOptions() {
        if (ready && optionSets.length > 0) {
            return optionSets[page].map((option, optionIdx) => (
                <Combobox.Option
                key={optionIdx}
                className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                }
                value={option}
                >
                {({ selected }) => (
                    <>
                    <span
                        className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                        }`}
                    >
                        {option}
                    </span>
                    {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    ) : null}
                    </>
                )}
                </Combobox.Option>
            ))
        } else {
            return null;
        }
    }

    return(
        <div className={props.hidden ? 'hidden' : 'relative z-50 min-w-[13rem]'}>
            <div className='text-[10pt] text-[#CCCCCC] ml-2 text-left font-medium'>{props.label}:</div>
            <Combobox 
                value={props.selected}
                onChange={props.setSelected}
                disabled={props.disabled}
                className='w-[15rem] disabled:animate-pulse disabled:opacity-50 rounded-lg disabled:cursor-not-allowed '
            >
                <div className='relative mt-1 w-full'>
                <Combobox.Input
                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 rounded-lg"
                    displayValue={(item) => item}
                    onChange={(event) => {setQuery(event.target.value); setPage(0);}}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>
                {/* <Combobox.Button className="text-black relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate font-bold">{props.selected}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </Combobox.Button> */}
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Combobox.Options className="absolute mt-1 w-full overflow-auto rounded-md bg-white pt-1 text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    <div className='container m-0 p-0 h-[90%]'>
                    <Combobox.Option
                        className={({ active }) =>
                            `relative cursor-default font-bold select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            }`
                        }
                        value={`${props.label}`}
                        disabled
                    >
                        {({ selected }) => (
                            <>
                            <span
                                className={`block truncate text-start font-bold ${
                                selected ? '' : ''
                                }`}
                            >
                                {`${props.label}`}
                            </span>
                            {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                            </>
                        )}
                    </Combobox.Option>
                    <RenderOptions />
                    </div>
                    </Combobox.Options>
                    
                </Transition>
                </div>
            </Combobox>
        </div>
    )
};

export function Input(props) {
    return(
        <div className={props.hidden ? 'hidden' : '' }>
            <div htmlFor={props.id} className='text-[10pt] text-[#CCCCCC] ml-2 text-left font-medium'>
                {props.label}:
            </div>
            <div className="mt-1">
                <input
                    type="text"
                    className='rounded-xl'
                    id={props.id}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    value={props.value}
                />
            </div>
        </div>
    )
}