import { useState, useEffect } from 'react';
import { Dropdown, PaginatedDropdown } from './InputComponents';
import SignInLock from '../../SignInLock';
import { use } from 'react';
import DualFeatureTimeSeriesLine from '../../Visuals/Charts/DualFeatureTimeSeriesLine';
// import DualAxisTimeSeriesLine from '../../Visuals/Charts/DualAxisTimeSeriesLine';
const brandsQuery = `
WITH test AS (
  SELECT
    asin,
    p.short_title,
    safe_cast(date as date) date,
    sum(impressions) impressions,
    sum(clicks) clicks,
    round(sum(sales), 2) sales,
    round(sum(cost), 2) cost,
    case
      when sum(sales) = 0 then 0
      else round(sum(cost)/sum(sales), 4)
    end acos
  FROM \`dwgrowvemarketplace.amazon_ads._view_asins\` a
  LEFT JOIN \`dwgrowvemarketplace.amazon_ads.lkp_advertiser_to_brand\` l
  ON a.brand = l.ads_brand
  LEFT JOIN \`dwgrowvemarketplace.justfoods.product_info\` p
  ON a.asin = p.c_asin
  WHERE a.brand = a.brand
  AND safe_cast(a.date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
  AND l.ads_brand = a.brand
  AND report_source in ('SP')
  AND a.asin LIKE 'B0%' AND LENGTH(a.asin) = 10
  GROUP BY a.asin, p.short_title, a.date
  HAVING sum(a.cost) > 0
)

SELECT brand,
  ARRAY_AGG(DISTINCT(asin)) asins
FROM \`dwgrowvemarketplace.amazon_ads._view_asins\`
WHERE asin LIKE 'B0%' 
    AND LENGTH(asin) = 10
    AND brand IS NOT NULL
    AND report_source in ('SP')
    AND safe_cast(date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
    AND cost > 0
    AND EXISTS (
        SELECT 1 FROM test
    )
GROUP BY brand
`

const oldBrandsQuery = `
WITH test AS (
  SELECT
    asin,
    p.short_title,
    safe_cast(date as date) date,
    sum(impressions) impressions,
    sum(clicks) clicks,
    round(sum(sales), 2) sales,
    round(sum(cost), 2) cost,
    case
      when sum(sales) = 0 then 0
      else round(sum(cost)/sum(sales), 4)
    end acos
  FROM \`dwgrowvemarketplace.amazon_ads._view_asins\` a
  LEFT JOIN \`dwgrowvemarketplace.amazon_ads.lkp_advertiser_to_brand\` l
  ON a.brand = l.ads_brand
  LEFT JOIN \`dwgrowvemarketplace.justfoods.product_info\` p
  ON a.asin = p.c_asin
  WHERE a.brand = a.brand
  AND safe_cast(a.date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
  AND l.ads_brand = a.brand
  AND report_source in ('SP')
  AND a.asin LIKE 'B0%' AND LENGTH(a.asin) = 10
  GROUP BY a.asin, p.short_title, a.date
  HAVING sum(a.cost) > 0
)

SELECT ARRAY_AGG(DISTINCT(brand)) brands
FROM \`dwgrowvemarketplace.amazon_ads._view_asins\`
WHERE asin LIKE 'B0%' 
    AND LENGTH(asin) = 10
    AND brand IS NOT NULL
    AND report_source in ('SP')
    AND safe_cast(date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
    AND cost > 0
    AND EXISTS (
        SELECT 1 FROM test
    )
`;
function queryWriter(selected_brand, selected_asin) {
    const baseQuery = `
    select
    asin,
    p.short_title,
    safe_cast(date as date) date,
    sum(impressions) impressions,
    sum(clicks) clicks,
    round(sum(sales), 2) sales,
    round(sum(cost), 2) cost,
    case
        when sum(sales) = 0 then 0
        else round(sum(cost)/sum(sales), 4)
    end acos
    from \`dwgrowvemarketplace.amazon_ads._view_asins\` a
    left join \`dwgrowvemarketplace.amazon_ads.lkp_advertiser_to_brand\` l
    on a.brand = l.ads_brand
    left join \`dwgrowvemarketplace.justfoods.product_info\` p
    on a.asin = p.c_asin
    where 1=1
    and safe_cast(date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
    and l.ads_brand = '${selected_brand}'
    and report_source in ('SP')
    and asin = '${selected_asin}'
    and a.asin LIKE 'B0%' AND LENGTH(asin) = 10
    group by asin, p.short_title, date
    having cost > 0
    order by date desc, cost desc
        `;
    return baseQuery
}

export default function AmazonAdsAsinByDay() {
    const [brands, setBrands] = useState(['Select Brand']);
    const [asinMap, setAsinMap] = useState({});
    const [asins, setAsins] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('Select Brand');
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedAsin, setSelectedAsin] = useState('Select ASIN');
    const [asinsReady, setAsinsReady] = useState(false);

    function getData() {
        // console.log(`Getting data for ${selectedBrand}`)
        var query = queryWriter(selectedBrand, selectedAsin);
        var body = JSON.stringify({ query: query })
        setAsinsReady(false)
        fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var results = JSON.parse(json.results)
                var asinList = results.map(item => item["asin"])
                setData(results)
                setAsinsReady(true)
                // setAsins(asinList)
            })
    };

    function getSelectedData() {
        setSelectedData(data.filter(item => item["asin"] === selectedAsin))
    }
    
    function getBrands() {
        // var body = JSON.stringify({ query: brandsQuery })
        // fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(json => {
        //         var raw_results = JSON.parse(json.results)
        //         var results = raw_results[0].brands
        //         setBrands(results)
        //     })
        var body = JSON.stringify({ query: brandsQuery})
        fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var raw_results = JSON.parse(json.results)
                var newBrands = raw_results.map(item => item["brand"])
                var newAsinMap = Object.assign({}, ...raw_results.map((item) => { return {[item['brand']]: item['asins']} }))
                // console.log(newAsinMap)
                setBrands(newBrands);
                setAsinMap(newAsinMap);
                setAsinsReady(true);
            });
    }

    useEffect(() => {
        if (brands.length <= 1) {
            return getBrands();
        } else {
            return;
        }
    }, [brands])

    useEffect(() => {
        function getSelectedAsins() {
            if (selectedBrand !== 'Select Brand') {
                // console.log(asinMap);
                setSelectedAsin('Select ASIN')
                setData([]);
                setSelectedData([]);
                console.log(asinMap[selectedBrand])
                setAsins(asinMap[selectedBrand]);
            } else {
                setSelectedAsin('Select ASIN')
                setData([]);
                setSelectedData([]);
                setAsins([]);
            }
        };
        getSelectedAsins();
        
    }, [selectedBrand]);

    useEffect(() => {
        if (selectedAsin !== 'Select ASIN') {
            return getData();
        } else {
            return;
        }
    }, [selectedAsin]);
    return(
        <SignInLock>
            <div className='grid px-40 py-12 grid-cols-12 gap-4 w-full inline bg-[#303038]'>      
                <div className='col-span-10 col-start-2 text-[#CCCCCC] flex justify-center text-center items-center '>
                    <span className='flex justify-center text-center text-lg font-bold'>Amazon Ads ASIN Performance by Day</span>
                </div>
                <div className='col-start-4 col-span-3 bg-inherit m-0 p-4 pb-0 text-inherit flex justify-end items-center flex-col'>
                    <PaginatedDropdown
                        options={brands}
                        label='Brand'
                        selected={selectedBrand}
                        disabled={brands.length === 0}
                        setSelected={(v) => setSelectedBrand(v)}
                    />
                </div>
                <div className='col-start-7 col-span-3 bg-inherit m-0 p-4 pb-0 text-inherit flex justify-end items-center flex-col'>
                    <PaginatedDropdown
                        options={asins}
                        label='ASIN'
                        selected={selectedAsin}
                        disabled={!asinsReady}
                        setSelected={(v) => setSelectedAsin(v)}
                    />
                </div>
                <div className='col-span-1 col-start-1 text-[#CCCCCC] flex justify-end items-center '>
                    <span className='transform -rotate-90'>Sales/Cost</span>
                </div>
                <div className={data.length > 0 ? 'col-span-10' : 'opacity-25 col-span-10'}>
                    <DualFeatureTimeSeriesLine
                        data={data}
                        yOneLabel='Cost'
                        yTwoLabel='Sales'
                        yOneColumn='cost'
                        yTwoColumn='sales'
                        yCurrency
                        xColumn='date'
                    />
                </div>
                <div className='col-span-1 text-[#CCCCCC] flex justify-start items-center '>
                </div>
                <div className='col-span-12 text-[#CCCCCC]'>Date</div>
            </div>
        </SignInLock>
    )
}