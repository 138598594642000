import { useState, useEffect, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Dropdown, PaginatedDropdown, Input } from './InputComponents';
import SignInLock from '../../SignInLock';
import { use } from 'react';
import DualFeatureTimeSeriesLine from '../../Visuals/Charts/DualFeatureTimeSeriesLine';
import { FunnelIcon, XMarkIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { filterData, getData, getBrands } from '../Utilities/AmazonAdsAsinByDayAllAsins';


function LoadingBlock(props) {
    return(
        <>
            <div
                className={props.loading === true && props.loadingGroup === 'brands'? 'my-48 col-span-12 text-[#CCCCCC] flex justify-center text-center items-center text-lg opacity-50' : 'hidden'}
            >
                <span className='animate-pulse flex justify-center text-center text-lg font-bold'>Loading Brands...</span>
            </div>
            <div
                className={props.selectedBrand === 'Select Brand' && props.loading === false ? 'my-48 col-span-12 text-[#CCCCCC] flex justify-center text-center items-center text-lg opacity-50' : 'hidden'}
            >
                <span className='flex justify-center text-center text-lg font-bold'>Please select a brand</span>
            </div>
            <div
                className={props.loading === true && props.loadingGroup === 'data' ? 'col-span-12 my-48 text-[#CCCCCC] flex justify-center text-center items-center text-lg opacity-50' : 'hidden'}
            >
                <span className='animate-pulse flex justify-center text-center text-lg font-bold'>Loading...</span>
            </div>
        </>
    )
}

function AsinChart(props) {
    const [asin] = useState(props.asin);
    const [productTitle] = useState(props.productTitle);

    return (
        <div 
            className={props.focusAsin === asin ? 'col-span-12 grid grid-cols-12' : 'col-span-4 grid hover:opacity-75 grid-cols-12'}
            onClick={props.focusAsin === asin ? null : () => props.setFocusAsin(asin)}    
        >
            <div 
                className={props.focusAsin === asin ? 'col-span-12 flex justify-end items-center' : 'hidden'}
            >
                <button
                    className='rounded-xl bg-[#ff8300] p-2 hover:opacity-100 opacity-75'
                    onClick={() => props.setFocusAsin('')}
                >
                    <XMarkIcon className='h-4 w-4 text-[#CCCCCC]' />
                </button>
            </div>
            <div className='col-span-10 col-start-2 text-[#CCCCCC] flex justify-center text-center items-center '>
                    <span className='flex justify-center text-center text-sm font-bold'>{asin}</span>
            </div>
            <div className='col-span-10 col-start-2 text-[#CCCCCC] flex justify-center text-center items-center '>
                    <div className='flex justify-center truncate whitespace-nowrap text-center text-xs font-bold line-clamp-1'>{productTitle}</div>
            </div>
            <div className='col-span-1 col-start-1 text-[#CCCCCC] flex justify-end items-center '>
                <span className='transform -rotate-90'>Sales/Cost</span>
            </div>
            <div className={props.data.length > 0 ? 'col-span-10' : 'opacity-25 col-span-10'}  onClick={() => props.setFocusAsin(asin)} >
                <DualFeatureTimeSeriesLine
                    data={props.data}
                    yOneLabel='Cost'
                    yTwoLabel='Sales'
                    yOneColumn='cost'
                    yTwoColumn='sales'
                    yCurrency
                    xColumn='date'
                />
            </div>
            <div className='col-span-1 text-[#CCCCCC] flex justify-start items-center '>
            </div>
            <div className='col-span-12 text-[#CCCCCC]'>Date</div>
    </div>
    )
};

function QueryInput(props) {
    const [queryInput, setQueryInput] = useState('');
    const [loadData, setLoadData] = useState(false);
    const [lastRunQuery, setLastRunQuery] = useState('');

    function runFilter() {
        props.getData(queryInput);
        setLastRunQuery(queryInput);
    };

    function resetFilter() {
        props.setFilteredData(props.data);
        setQueryInput('');
    }
    return (
        <div className='col-span-4 bg-inherit m-0 p-4 pb-0 text-inherit flex justify-start items-end'>
        <Input
            id='query'
            label='Query'
            placeholder='Filter'
            value={queryInput}
            onChange={(e) => setQueryInput(e.target.value)}
        />
        <button
            className='bg-[#63A60A] hover:opacity-75 disabled:opacity-50 font-bold py-2 px-4 mx-2 rounded-xl focus:outline-none focus:shadow-outline'
            type='button'
            disabled={queryInput === '' || Object.keys(props.filteredData).length === 0}
            onClick={() => runFilter()}
        >
            <FunnelIcon className='text-[#CCCCCC] h-6 w-6' />
        </button>
        <button
            className='bg-[#63A60A] hover:opacity-75 disabled:opacity-50 font-bold py-2 px-4 mx-2 rounded-xl focus:outline-none focus:shadow-outline'
            type='button'
            disabled={queryInput === lastRunQuery}
            onClick={() => resetFilter()}
        >
            <ArrowPathIcon className='text-[#CCCCCC] h-6 w-6' />
        </button>
    </div>
    )
}


export default function AmazonAdsAsinByDayAllAsins() {
    const [brands, setBrands] = useState([]);
    const [asinMap, setAsinMap] = useState({});
    const [selectedBrand, setSelectedBrand] = useState('Select Brand');
    const [data, setData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [productMap, setProductMap] = useState({});
    const [reverseProductMap, setReverseProductMap] = useState({});
    const [asinsReady, setAsinsReady] = useState(false);
    const [filterBy, setFilterBy] = useState('ASIN');
    // const [queryInput, setQueryInput] = useState('');
    const [focusAsin, setFocusAsin] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingGroup, setLoadingGroup] = useState('');


    // useEffect(() => {
    //     if (selectedBrand !== 'Select Brand') {
    //         return getData(setData, selectedBrand, setAsinsReady, setFilteredData, setProductMap, setReverseProductMap);
    //     } else {
    //         return;
    //     }
    // }, [selectedBrand]);

    useEffect(() => {
        function checkForBrands() {
            if (brands.length <= 1) {
                console.log('preparing to load brands');
                setLoading(true);
                setLoadingGroup('brands');
                return;
            } else {
                return;
            }
        };
        checkForBrands();
    }, [brands]);

    useEffect(() => {
        function loadBrands() {
            if (loading === true && loadingGroup === 'brands') {
                console.log('loading brands');
                getBrands(brands, setBrands);
                setLoading(false);
                setLoadingGroup('');
                return;
            } else {
                return;
            }
        };
        loadBrands();
    }, [loading, loadingGroup, brands]);

    useEffect(() => {
        function checkData() {
            if (selectedBrand !== 'Select Brand') {
                console.log('preparing to load data');
                setLoading(true);
                setLoadingGroup('data');
                return;
            } else {
                return;
            }
        };
        checkData();
    }, [selectedBrand]);

    useEffect(() => {
        function loadData() {
            if (loading === true && loadingGroup === 'data') {
                console.log('loading data');
                getData(setData, selectedBrand, setAsinsReady, setFilteredData, setProductMap, setReverseProductMap);
                setLoading(false);
                setLoadingGroup('');
                return;
            } else {
                return;
            };
        };
        loadData();
    }, [loading, loadingGroup, selectedBrand]);

    

    function getFilteredData(queryInput) {
        return filterData(data, setFilteredData, queryInput, filterBy, productMap, reverseProductMap);
    }

    // useEffect(() => {
    //     if (brands.length <= 1) {
    //         setLoading(true);
    //         setLoadingGroup('brands');
    //         getBrands(brands, setBrands);
    //         setLoading(false);
    //         setLoadingGroup('');
    //     } else {
    //         return;
    //     }
    // }, [brands]);
    return(
        <SignInLock>
            <div className='grid px-40 py-12 grid-cols-12 gap-4 min-h-screen content-start w-full inline bg-[#303038]'>      
                <div className='col-span-10 col-start-2 text-[#CCCCCC] flex justify-center text-center items-center '>
                    <span className='flex justify-center text-center text-lg font-bold'>Amazon Ads ASIN Performance by Day</span>
                </div>
                <div className='col-start-1 col-span-4 bg-inherit m-0 p-4 pb-0 text-inherit flex justify-end items-center flex-col'>
                    <PaginatedDropdown
                        options={brands}
                        label='Brand'
                        selected={selectedBrand}
                        disabled={brands.length === 0}
                        setSelected={(v) => setSelectedBrand(v)}
                    />
                </div>
                <div className='col-span-3 bg-inherit m-0 p-4 pb-0 text-inherit flex justify-end items-center flex-col'>
                    <Dropdown
                        options={['ASIN', 'Product Title']}
                        label='Filter By'
                        selected={filterBy}
                        disabled={false}
                        setSelected={(v) => setFilterBy(v)}
                    />
                </div>
                <QueryInput 
                    filterBy={filterBy} 
                    getData={getFilteredData}
                    data={data}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                />
                <LoadingBlock loading={loading} loadingGroup={loadingGroup} selectedBrand={selectedBrand} />
                {Object.keys(filteredData).length > 0 ? Object.keys(filteredData).map(asin => <AsinChart key={asin} productTitle={productMap[asin]} focusAsin={focusAsin} setFocusAsin={setFocusAsin} data={filteredData[asin]} asin={asin} />) : null}
            </div>
        </SignInLock>
    )
};