import { VictoryChart, VictoryLegend, VictoryStack, Background, VictoryLine, VictoryTheme, VictoryAxis, VictoryVoronoiContainer, VictoryTooltip, VictoryLabel, VictoryBar } from 'victory';
import { useState, useEffect } from 'react';
import { DualFeatureTimeSeries, getAxisString } from '../DataFormatting/DualFeatureTimeSeries';
import { getToolTipString } from '../DataFormatting/DualFeatureTimeSeries';
import { legendConfig, yAxisConfig, xAxisConfig } from '../DefaultConfigs/DualFeatureTimeSeries';
// export default function DualFeatureTimeSeriesLine(props: DATSLineProps) {
export default function DualFeatureTimeSeriesLine(props) {
    const [yOneLabel] = useState(props.yOneLabel);
    const [yTwoLabel] = useState(props.yTwoLabel);
    const [yOneColumn] = useState(props.yOneColumn);
    const [yTwoColumn] = useState(props.yTwoColumn);
    const [yCurrency] = useState(props.yCurrency);
    const [xColumn] = useState(props.xColumn);
    const [data, setData] = useState({
        data: [],
        maxYTwo: 10,
        maxYOne: 10,
        maxDate: 1737417600000,
        minYTwo: 0,
        minYOne: 0,
        minDate: 1729728000000,
        dateTicks: [],
        yTwoTicks: [],
        yOneTicks: [],
        yTicks: []
    });

    // useEffect(() => {
    //     function checkPropsUpdate() {
    //         // console.log('props updated');
    //     };
    //     checkPropsUpdate();
    // }, [props]);
    
    useEffect(() => {
        function getUpdatedData() {
            if (props.data.length > 0) {
                const preparedData = new DualFeatureTimeSeries(props.data, yTwoColumn, yOneColumn, xColumn);
                return setData({
                    data: preparedData.normalizedData,
                    maxYTwo: preparedData.maxA,
                    maxYOne: preparedData.maxB,
                    maxDate: preparedData.maxDate,
                    minYTwo: preparedData.minA,
                    minYOne: preparedData.minB,
                    minDate: preparedData.minDate,
                    dateTicks: preparedData.dateTicks,
                    yTicks: preparedData.yTicks
                });
            } else {
                return;
            }
        };
        getUpdatedData();
}, [props.data]);

        return (
                <VictoryChart
                    theme={VictoryTheme.clean}
                    containerComponent={
                        <VictoryVoronoiContainer />
                    }
                    height={500}
                    width={800}
                    style={{
                        background: {
                            fill: "#252423"
                        }, 
                        labels: {
                            fill: "#95B1B9", color: "#95B1B9"
                        }
                    }}
                    title="Amazon Ads ASIN Performance"
                    padding={{left: 50, right: 50, top: 50, bottom: 50}}
                >
                    <VictoryLegend {...legendConfig(props.yOneLabel, props.yTwoLabel)} />
                   {props.data.length > 0 ? <VictoryAxis
                        {...yAxisConfig(data, getAxisString, yCurrency)}
                    /> : null}
                    {props.data.length > 0 ?
                    <VictoryAxis
                        {...xAxisConfig(data)} 
                    /> : null}
                    <VictoryLine 
                        data={data.data}
                        y={yTwoColumn}
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={10}
                                flyoutStyle={{ 
                                    fill: "#303036"
                                }}
                                style={{
                                    fill: "#63A60A"
                                }}
                                flyoutPadding={10}
                            />
                        }
                        labels={({ datum }) => getToolTipString(datum) }

                        style={{ data: { stroke: "#63A60A" } }}
                    />
                    <VictoryLine 
                        labels={({ datum }) => getToolTipString(datum) }
                        y={yOneColumn}
                        data={data.data}
                        style={{ data: { stroke: "#C4C4C4" } }}
                        labelComponent={
                            <VictoryTooltip 
                                cornerRadius={10}
                                flyoutStyle={{ 
                                    fill: "#303037"
                                }}
                                style={{
                                    fill: "#63A60A"
                                }}
                                flyoutPadding={10}
                            />
                        }
                    />
                </VictoryChart>
        );
};