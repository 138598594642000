import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LaunchAnalysis from './Dashboards/LaunchAnalysis/LaunchAnalysis';
import LogIn from './LogIn';
import AmazonAdsAsinByDay from './Dashboards/AmazonAdsAsinByDay/AmazonAdsAsinByDay';
// import AmazonAdsAsinByDayAllAsins from './Dashboards/AmazonAdsAsinByDay/AmazonAdsAsinByDayAllAsins';
import AmazonAdsAsinByDayAllAsinsRewrite from './Dashboards/AmazonAdsAsinByDay/AmazonAdsAsinByDayAllAsinsRewrite';
import Builder from './Visuals/Builder';


export const router = createBrowserRouter([
    {
        path: '/',
        element: <LogIn />,
    },
    {
        path: '/launch-analysis',
        element: <LaunchAnalysis />
    },
    {
        path: '/amazon-ads-asin-by-day',
        element: <AmazonAdsAsinByDay />
    },
    // {
    //     path: '/amazon-ads-asin-by-day-all',
    //     element: <AmazonAdsAsinByDayAllAsins />
    // },
    {
        path: '/amazon-ads-asin-by-day-all',
        element: <AmazonAdsAsinByDayAllAsinsRewrite />
    },
    {
        path: '/login',
        element: <LogIn />
    },
    {
        path: '/visual-editor',
        element: <Builder />
    }
])