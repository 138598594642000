import { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { Tab } from '@headlessui/react';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
function PageSwitcher(props) {
    return(
        <div className="col-span-8 col-start-3 h-[50px] m-0 p-0">
            <Tab.Group>
                <Tab.List
                    className="flex space-x-1 rounded-xl bg-[#63A60A]/20 p-1"
                >
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                            'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                            selected
                                ? 'bg-white text-[#63A60A] shadow'
                                : 'text-white bg-[#63A60A] hover:bg-[#63A60A]/[0.2] hover:text-white'
                            )
                        }
                    >
                        Config
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                            'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                            selected
                                ? 'bg-white text-[#63A60A] shadow'
                                : 'text-white bg-[#63A60A] hover:bg-[#63A60A]/[0.2] hover:text-white'
                            )
                        }
                    >
                        Visuals
                    </Tab>
                </Tab.List>
            </Tab.Group>
        </div>
    )
};
export default function Builder() {
    const [query, setQuery] = useState('');
    const [currentQuery, setCurrentQuery] = useState('');
    const [rawData, setRawData] = useState([]);

    function getData() {
        var body = JSON.stringify({ query: query });
        fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var results = JSON.parse(json.results)
                setRawData(results)
            })
    };

    useEffect(() => {
        if (currentQuery.length > 0) {
            return getData();
        } else {
            return;
        }
    }, [currentQuery, getData]);
    return(
        <div
            className="grid grid-cols-12 bg-[#303037] h-[100vh] w-[100vw]"
        >
            <PageSwitcher />
            <div
                className="col-span-6 p-4"
            >
                <div className=" rounded-xl pt-4 shadow-lg shadow-[#63A60A] h-full bg-white">
                    <AceEditor
                        // height="100%"
                        width="100%"
                        value={query}
                        onChange={setQuery}
                        mode="sql"
                        // theme="monokai"
                        hightlightActiveLine={true}
                        fontSize="14px"
                        setOptions={{
                            enableBasicAutocompletion: true
                          }}
                        
                    />
                    <button 
                        className="bg-[#63A60A] text-white rounded-b-xl p-2 w-full bottom-0 relative disabled:opacity-50"
                        onClick={() => setCurrentQuery(query)}
                        disabled={query.length === 0}
                    >
                        Run Query
                    </button>
                </div>
            </div>
            <div className="col-span-6 p-4">
            </div>
        </div>
    )
}

