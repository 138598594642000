import { useState } from "react";
import { DatePicker, Text, Metric, Button, Accordion, AccordionHeader, AccordionBody, LineChart, Card, BarChart, Title } from "@tremor/react";
import Toggle from './Toggle';
import { ChevronUpIcon, ChevronDownIcon, ChartBarIcon, TableCellsIcon } from '@heroicons/react/20/solid'
import Table from './Table';
import { useCSVDownloader } from 'react-papaparse';
import SignInLock from "../../SignInLock";
function queryWriter(metrics) {
    var baseQuery = `with core_brands as (
  

      select distinct brand

      from \`dwgrowvemarketplace.justfoods.product_info\`

      where 1=1

        and classification = 'Core'

    ),

 

    launch_asins as (

      select distinct asin

      from \`dwgrowvemarketplace.pbi._view_new_product_sales\` n

      ${metrics.coreBrandsOnly ? '' : '--'}inner join core_brands c --INPUT1: core brand only checkbox. If core only, leave these lines uncommented

        ${metrics.coreBrandsOnly ? '' : '--'}on n.brand = c.brand --INPUT1: core brand only checkbox. If core only, leave these lines uncommented

      where 1=1

        and date >= '${metrics.startDate}' 

        and date <= '${metrics.endDate}' 

        and days_since_launch = 1

    ),

 

    post_launch_sales as (

      select n.* from \`dwgrowvemarketplace.pbi._view_new_product_sales\` n

      inner join launch_asins l

        on n.asin = l.asin

      where 1=1

        and date > '${metrics.endDate}'

    )
    select * from post_launch_sales`
    return baseQuery
    
};

function getKPIs(data) {
    var asins = []
    for (const x of data) {
        if (!asins.includes(x.asin)) {
            asins.push(x.asin);
        } else {
            continue
        };
    };
    var sales = Array.from(data, x => x.sales);
    var totalSales = sales.reduce((a, b) => a + b, 0);
    var launchSales = Array.from(data, x => x.launch_sales_app);
    var totalLaunchSales = launchSales.reduce((a, b) => a + b, 0);
    var launchSalesPercent = ((totalLaunchSales / totalSales) * 100);
    var ppcCost = Array.from(data, x => x.ppc_cost);
    var totalPPCCost = ppcCost.reduce((a, b) => a + b, 0);
    var units = Array.from(data, x => x.units);
    var totalUnits = units.reduce((a, b) => a + b, 0);
    var launchProfit = Array.from(data, x => x.launch_profit_app);
    var totalLaunchProfit = launchProfit.reduce((a, b) => a + b, 0);
    var profit = Array.from(data, x => x.profit);
    var totalProfit = profit.reduce((a, b) => a + b, 0);
    var launchProfitPercent = ((totalLaunchProfit / totalProfit) * 100);
    var margin = ((totalProfit / totalSales) * 100);
    var KPIs = {
        asins: asins.length,
        launchSales: `$${totalSales.toLocaleString('en-US', {maximumFractionDigits: 0})}`,
        launchSalesPercent: `${launchSalesPercent.toLocaleString('en-US', { maximumFractionDigits: 0 })}%`,
        launchProfit: `$${totalProfit.toLocaleString('en-US', { maximumFractionDigits: 0 })}`,
        launchProfitPercent: `${launchProfitPercent.toLocaleString('en-US', { maximumFractionDigits: 1 })}%`,
        launchUnits: totalUnits.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        margin: `${margin.toLocaleString('en-US', { maximumFractionDigits: 1 })}%`,
        ppcCost: `$${totalPPCCost.toLocaleString('en-US', {maximumFractionDigits: 0})}`

    };
    return KPIs
};

function getLineChartData(data, endDate) {
    var dates = [];
    var end = new Date(endDate).valueOf();
    var filteredData = data.filter(x => x.date > end)
    for (const x of filteredData) {
        if (!dates.includes(x.Date)) {
            dates.push(x.Date);
        } else {
            continue;
        };
    };
    var allData = []
    for (const x of dates) {
        let lineChartData = {}
        lineChartData.Date = x;
        let salesObjects = filteredData.filter(y => y.Date === x);
        let sales = Array.from(salesObjects, z => z.sales)
        lineChartData['Sales'] = sales.reduce((a, b) => a + b, 0)
        allData.push(lineChartData);
    };
    
    
    return allData;
};

function getBarChartData(data) {
    var brands = [];
    for (const x of data) {
        if (!brands.includes(x.brand)) {
            brands.push(x.brand);
        } else {
            continue;
        };
    };
    var brandData = [];
    for (const x of brands) {
        let barChartData = {};
        let brandObjects = data.filter(y => y.brand === x);
        let brandSales = Array.from(brandObjects, y => y.sales);
        barChartData.Brand = x;
        barChartData['Sales'] = brandSales.reduce((a, b) => a + b, 0);
        brandData.push(barChartData);

    };
    let sortedData = brandData.sort((a, b) => b['Sales'] - a['Sales']);
    return sortedData;
}

function formatDates(data) {
    for (const x of data) {
        let newDate = new Date(x.date).toLocaleDateString('en-us', { year: 'numeric', month: 'numeric' });
        x.Date = newDate;
        x['Sales'] = x.sales;
        x['Launch Sales'] = x.launch_sales_app;
        x['Launch Sales %'] = (x.launch_sales_app/x.sales)*100
    };
    var sortedData = data.sort((a, b) => a.date - b.date);
    return sortedData;
};

function formatSalesPercent(num) {
    return `${num}%`;
}

function getAsins(data) {
    var asins = []
    for (const x of data) {
        if (!asins.includes(x.asin)) {
            asins.push(x.asin);
        } else {
            continue
        };
    };
    return asins;
};



function getSales(data) {
    var sales = Array.from(data, x => x.sales);
    return sales.reduce(Math.sum);
};

function getTableData(data) {
    var asins = [];
    for (const x of data) {
        if (!asins.includes(x.asin)) {
            asins.push(x.asin);
        } else {
            continue;
        }
    };
    var asinData = [];
    for (const y of asins) {
        let asinObject = {};
        let asinRows = data.filter(z => z.asin === y);
        let description = asinRows[0].short_title;
        let brand = asinRows[0].brand;
        let sales = Array.from(asinRows, u => u.sales);
        let units = Array.from(asinRows, u => u.units);
        let profit = Array.from(asinRows, u => u.profit);
        asinObject.Brand = brand;
        asinObject.Description = description;
        asinObject.Sales = `$${sales.reduce((a, b) => a + b, 0).toLocaleString('en-US', { maximumFractionDigits: 0 })
            }`;
        asinObject.SalesRaw = sales.reduce((a, b) => a + b, 0)
        asinObject.Profit = `$${profit.reduce((a, b) => a + b, 0).toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
        asinObject.ProfitRaw = profit.reduce((a, b) => a + b, 0)
        asinObject.Units = units.reduce((a, b) => a + b, 0).toLocaleString('en-US', { maximumFractionDigits: 0 });
        asinObject.UnitsRaw = units.reduce((a, b) => a + b, 0);
        asinObject.ASIN = y;
        asinData.push(asinObject);
    };
    console.log(asinData);
    return asinData;
}

function currencyFormatter(num) {
    return `$${num.toLocaleString('en-US', {maximumFractionDigits: 0})}`
}
function LaunchAnalysis(props) {
    const [launchDateStart, setLaunchDateStart] = useState(new Date('01-01-2023'));
    const [launchDateEnd, setLaunchDateEnd] = useState(new Date('04-01-2023'));
    const [coreBrandsOnly, setCoreBrandsOnly] = useState(true);
    const [data, setData] = useState(null);
    const [KPIs, setKPIs] = useState(null);
    const [lineChartData, setLineChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);
    const [showDataDropdown, setShowDataDropdown] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [showData, setShowData] = useState('dashboard');
    const [tableData, setTableData] = useState(null);
    const [csv, setCSV] = useState(null);
    const { CSVDownloader, Type } = useCSVDownloader();
    function getData() {
        var metrics = {
            coreBrandsOnly: coreBrandsOnly,
            startDate: `${launchDateStart.getFullYear()}-${launchDateStart.getMonth() + 1}-${launchDateStart.getDate()}`,
            endDate: `${launchDateEnd.getFullYear()}-${launchDateEnd.getMonth() + 1}-${launchDateEnd.getDate()}`,
        }
        console.log(metrics)
        var query = queryWriter(metrics);
        console.log(query);
        var body = JSON.stringify({ query: query });
        console.log(body);
        fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var results = JSON.parse(json.results)
                var formatted = formatDates(results)
                setData(formatted);
                setFilteredData(formatted);
                setKPIs(getKPIs(formatted));
                setLineChartData(getLineChartData(formatted, launchDateEnd));
                setBarChartData(getBarChartData(formatted));
                setTableData(getTableData(formatted));
                setLoading(false);
            })
    };

    
    
    return (
        <SignInLock>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 rounded-t-lg">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Launch Sales Dashboard</h1>
                </div>
            </header>
            <div className='grid grid-cols-1 border-2 border-gray-100 rounded-lg'>
                <div className='grid grid-cols-5 rounded-lg bg-gray-100 gap-3 items-center'>
                    <div className='flex col-span-5 text-gray-600 text-center text-2xl font-bold hover:cursor-pointer py-5 justify-center' onClick={() => { setShowDataDropdown(prev => !prev) }}>Data {showDataDropdown ? <ChevronUpIcon className='h-4 w-4 m-3 text-gray-600' /> : <ChevronDownIcon className='h-4 w-4 m-3 text-gray-600' />}</div>
                    <div className={showDataDropdown ? 'm-3' : 'hidden' }>
                        <Text color='gray-600'>Launch Date Start</Text>
                        <DatePicker className='z-50 flex' value={launchDateStart} enableYearNavigation onValueChange={setLaunchDateStart} minDate={new Date('2019-01-01')} className="max-w-sm mx-auto" />
                    </div>
                    <div className={showDataDropdown ? 'm-3' : 'hidden'}>
                        <Text color='gray-600'>Launch Date End</Text>
                        <DatePicker className='z-0' value={launchDateEnd} enableYearNavigation onValueChange={setLaunchDateEnd} minDate={new Date('2019-01-01')} className="max-w-sm mx-auto" />
                    </div>
                    <div className={showDataDropdown ? 'm-3' : 'hidden'}>
                        <Toggle
                            update={setCoreBrandsOnly}
                            value={coreBrandsOnly}
                            label='Core Brands Only'
                        />
                    </div>
                    <div className={showDataDropdown ? 'm-3' : 'hidden'}>
                        <button
                            className={loading ? 'bg-blue-500 font-bold text-white rounded-md p-3 animate-pulse w-24' : 'bg-blue-500 font-bold text-white rounded-md p-3'}
                            onClick={() => { setLoading(true); getData(); } }
                        >{loading ? '...' : 'Get Data'}</button>
                    </div>
                    <div className={showDataDropdown ? 'm-3' : 'hidden'}>
                        <CSVDownloader
                            type={Type.Button}
                            filename={`Product Launch Analysis ${launchDateStart} - ${launchDateEnd}`}
                            bom={true}
                            config={{
                                delimiter: ','
                            }}
                            data={tableData}
                        >Export CSV</CSVDownloader>
                    </div>
                    <div
                        onClick={() => setShowData('dashboard')}
                        className={showData === 'dashboard' ? 'col-span-2 border-e-2 border-t-2 flex rounded-xl hover:cursor-pointer font-bold text-center text-white bg-[#64A70A]/70 justify-center py-4'
                            : 'col-span-2 border-e-2 border-t-2 flex rounded-xl hover:cursor-pointer font-bold text-center text-gray-600 justify-center py-4'}
                    >
                        Dashboard <ChartBarIcon className={showData === 'dashboard' ? 'h-5 w-5 ml-3 text-white' : 'h-5 w-5 ml-3 text-gray-600'}/>
                    </div>
                    <div
                        onClick={() => setShowData('table')}
                        className={showData === 'table' ? 'col-span-2 col-start-4 border-t-2 border-s-2 flex rounded-xl hover:cursor-pointer font-bold text-center text-white bg-[#64A70A]/70 justify-center py-4'
                            : 'col-span-2 col-start-4 border-s-2 border-t-2 flex rounded-xl hover:cursor-pointer font-bold text-center text-gray-600 justify-center py-4'}
                    >
                        Table <TableCellsIcon className={showData === 'table' ? 'h-5 w-5 ml-3 text-white' : 'h-5 w-5 ml-3 text-gray-600'} />
                    </div>
                </div>
                <div className={showData === 'dashboard' ? 'grid grid-cols-3 gap-3 pt-3' : 'hidden'}>
                    <Card>
                        <Title>New ASINs</Title>
                        <Metric className={loading ? 'text-center animate-pulse' : 'text-center'} color='green'>{KPIs === null ? loading ? 'Loading' : '...' : KPIs.asins}</Metric>
                    </Card>
                    <Card>
                        <Title>Launch Sales</Title>
                        <Metric className={loading ? 'text-center animate-pulse' : 'text-center'} color='green'>{KPIs === null ? loading ? 'Loading' : '...' : KPIs.launchSales}</Metric>
                    </Card>
                    <Card>
                        <Title>Margin</Title>
                        <Metric className={loading ? 'text-center animate-pulse' : 'text-center'} color='green'>{KPIs === null ? loading ? 'Loading' : '...' : KPIs.margin}</Metric>
                    </Card>
                    <Card>
                        <Title>Launch Profit</Title>
                        <Metric className={loading ? 'text-center animate-pulse' : 'text-center'} color='green'>{KPIs === null ? loading ? 'Loading' : '...' : KPIs.launchProfit}</Metric>
                    </Card>
                    <Card>
                        <Title>PPC Spend</Title>
                        <Metric className={loading ? 'text-center animate-pulse' : 'text-center'} color='green'>{KPIs === null ? loading ? 'Loading' : '...' : KPIs.ppcCost}</Metric>
                    </Card>
                    <Card>
                        <Title>Launch Units</Title>
                        <Metric className={loading ? 'text-center animate-pulse' : 'text-center'} color='green'>{KPIs === null ? loading ? 'Loading' : '...' : KPIs.launchUnits}</Metric>
                    </Card>
                    <div className='col-span-3'>
                        <Card>
                            <Title className='text-center'>Launch Sales By Date</Title>
                            <LineChart
                                noDataText={loading ? "Retrieving Data" : "Select dates From 'Data' tab above to view"}
                                data={lineChartData}
                                index='Date'
                                colors={['green']}
                                className={loading ? 'animate-pulse' : ''}
                                categories={['Sales']}
                                valueFormatter={currencyFormatter}
                                curveType='linear'
                            />
                        </Card>
                    </div>
                    <div className='col-span-3'>
                        <Card>
                            <Title className='text-center'>Launch Sales By Brand</Title>
                            <BarChart
                                noDataText={loading ? "Retrieving Data" : "Select dates From 'Data' tab above to view"}
                                className={loading ? 'animate-pulse' : ''}
                                data={barChartData}
                                colors={['green']}
                                index='Brand'
                                categories={['Sales']}
                                valueFormatter={currencyFormatter}
                            />
                        </Card>
                    </div>
                </div>
                <div className={showData === 'table' ? '' : 'hidden'}>
                    <Card className='h-screen mt-3'>
                        <Table
                            columns={['ASIN', 'Brand', 'Description', 'Sales', 'Profit', 'Units']}
                            columnTypes={{ ASIN: 'string', Brand: 'string', Description: 'string', Sales: 'number', Profit: 'number', Units: 'number' }}
                            data={tableData}
                            />
                    </Card>
                </div>
            </div>
        </SignInLock>
    )
};
export default LaunchAnalysis