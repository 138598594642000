export class DualFeatureTimeSeries {
    constructor(data, yColumnA, yColumnB, xColumn='date') {
        this.data = data;
        this.yColumnA = yColumnA;
        this.yColumnB = yColumnB;
        this.xColumn = xColumn;
        this.getMaxValues();
        this.getMinValues();
        this.getDateTicks();
        this.normalizeData();
        this.getYTicks();
    };

    getMaxValues() {
        this.maxA = Math.max(...this.data.map(row => row[this.yColumnA]));
        this.maxB = Math.max(...this.data.map(row => row[this.yColumnB]));
        this.maxDate = Math.max(...this.data.map(row => new Date(row[this.xColumn])));
        const commonMax = Math.max(...this.data.map(row => row[this.yColumnA]), ...this.data.map(row => row[this.yColumnB]));
        this.scaleMax = commonMax;
    };
    getMinValues() {
        this.minDate = Math.min(...this.data.map(row => new Date(row[this.xColumn])));
        this.scaleMin = 0;
    };
    addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };
    getDateTicks() {
        const numberOfTicks = 10;
        const oneDay = 24 * 60 * 60 * 1000;
        const daysBetween = Math.round((this.maxDate - this.minDate) / oneDay);
        const interval = Math.floor(daysBetween / (numberOfTicks + 1));
        const dateTicks = [
            ...Array.from({ length: numberOfTicks + 1 }, (_, i) => i).map(z => z * interval).map(date => this.addDays(this.minDate, date)),
            new Date(this.maxDate)
        ].sort((a, b) => a - b);
        this.dateTicks = dateTicks;
    };

    normalize(value, oldMax, newMin, newMax) {
        return ((value - 0) / (oldMax - 0)) * (newMax - newMin) + newMin;
    };

    denormalize(value, oldMax, newMin, newMax) {
        return ((value - newMin) / (newMax - newMin)) * (oldMax - 0) + 0;
    };

    normalizeData() {
        const columnAName = `${this.yColumnA}UnNormalized`;
        const columnBName = `${this.yColumnB}UnNormalized`;
        this.normalizedData = this.data.map(row => ({
            ...row,
            x: row[this.xColumn],
            [this.yColumnA]: row[this.yColumnA],
            [this.yColumnB]: row[this.yColumnB],
            [columnAName]: row[this.yColumnA],
            [columnBName]: row[this.yColumnB]
        }));
    };

    createYTicks(max, numTicks = 10) {
        const rawStep = this.scaleMax / (numTicks - 1);
        const magnitude = Math.pow(numTicks+1, Math.floor(Math.log10(rawStep)));
        const refinedStep = Math.ceil(rawStep / magnitude) * magnitude;
        const ticks = Array.from({ length: numTicks + 1 }, (_, i) => i).map(z => z * refinedStep).sort((a, b) => a - b);
        return ticks;
    }

    getYTicks() {  
        const yTicks = this.createYTicks(this.scaleMax);
        const yTickLabels = yTicks
        // Array.from(yTicks, tickValue => this.denormalize(tickValue, Math.max(this.maxA, this.maxB),  this.scaleMin, this.scaleMax));
        this.yTickLabels = yTickLabels;
        this.yTicks = yTicks;
    }
    // getYTicks() {  
    //     const yTicks = this.createYTicks(this.scaleMax);
    //     this.yTicks = yTicks;
    // }
};

export function getAxisString(number, currency) {
    if (currency) {
        try {
            if (number < 1000) {
                return `$${number.toFixed(0)}`;
            } else if (number < 1000000) {
                return `$${(number/1000).toFixed(2)}k`;
            } else {
                return `$${(number/1000000).toFixed(2)}M`;
            };
        } catch (error) {
            return '';
        };
    } else {
        try {
            if (number < 1000) {
                return `${number.toFixed(0)}`;
            } else if (number < 1000000) {
                return `${(number/1000).toFixed(2)}k`;
            } else {
                return `${(number/1000000).toFixed(2)}M`;
            };
        } catch (error) {
            return '';
        };
    };
};

export function getToolTipString(datum) {
    const salesFormatted = `Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(datum.salesUnNormalized)}`;
    const costFormatted = `Cost: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(datum.costUnNormalized)}`;
    const dateFormatted = `Date: ${new Date(datum.x).toLocaleDateString('en-us', { year: '2-digit', month: 'short', day: 'numeric' })}`;
    const acosFormatted = `ACOS: ${(datum.acos * 100).toFixed(0)}%`;
    const clicksFormatted = `Clicks: ${datum.clicks.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`;
    const impressionsFormatted = `Impressions: ${datum.impressions.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`;
    return `${salesFormatted}\n${costFormatted}\n${dateFormatted}\n${acosFormatted}\n${clicksFormatted}\n${impressionsFormatted}`
};