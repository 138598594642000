function queryWriter(selected_brand) {
    const baseQuery = `
    select
    asin,
    p.short_title,
    safe_cast(date as date) date,
    sum(impressions) impressions,
    sum(clicks) clicks,
    round(sum(sales), 2) sales,
    round(sum(cost), 2) cost,
    case
        when sum(sales) = 0 then 0
        else round(sum(cost)/sum(sales), 4)
    end acos
    from \`dwgrowvemarketplace.amazon_ads._view_asins\` a
    left join \`dwgrowvemarketplace.amazon_ads.lkp_advertiser_to_brand\` l
    on a.brand = l.ads_brand
    left join \`dwgrowvemarketplace.justfoods.product_info\` p
    on a.asin = p.c_asin
    where 1=1
    and safe_cast(date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
    and l.ads_brand = '${selected_brand}'
    and report_source in ('SP')
    and a.asin LIKE 'B0%' AND LENGTH(asin) = 10
    group by asin, p.short_title, date
    having cost > 0
    order by date desc, cost desc
        `;
    return baseQuery
};

const brandsQuery = `
WITH test AS (
  SELECT
    asin,
    p.short_title,
    safe_cast(date as date) date,
    sum(impressions) impressions,
    sum(clicks) clicks,
    round(sum(sales), 2) sales,
    round(sum(cost), 2) cost,
    case
      when sum(sales) = 0 then 0
      else round(sum(cost)/sum(sales), 4)
    end acos
  FROM \`dwgrowvemarketplace.amazon_ads._view_asins\` a
  LEFT JOIN \`dwgrowvemarketplace.amazon_ads.lkp_advertiser_to_brand\` l
  ON a.brand = l.ads_brand
  LEFT JOIN \`dwgrowvemarketplace.justfoods.product_info\` p
  ON a.asin = p.c_asin
  WHERE a.brand = a.brand
  AND safe_cast(a.date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
  AND l.ads_brand = a.brand
  AND report_source in ('SP')
  AND a.asin LIKE 'B0%' AND LENGTH(a.asin) = 10
  GROUP BY a.asin, p.short_title, a.date
  HAVING sum(a.cost) > 0
)

SELECT ARRAY_AGG(DISTINCT(brand)) brands
FROM \`dwgrowvemarketplace.amazon_ads._view_asins\`
WHERE asin LIKE 'B0%' 
    AND LENGTH(asin) = 10
    AND brand IS NOT NULL
    AND report_source in ('SP')
    AND safe_cast(date as date) >= date_sub(current_date('America/New_York'), interval 90 DAY)
    AND cost > 0
    AND EXISTS (
        SELECT 1 FROM test
    )
`;

export function filterData(data, setFilteredData, queryInput, filterBy, productMap, reverseProductMap) {
    if (Object.keys(data).length === 0) {
        return;
    } else {
        if (filterBy === 'ASIN') {
            //  const asinMatches = Array.from(Object.keys(productMap), asin => asin.toLowerCase().includes(queryInput.toLowerCase()));
            const asinMatches = Object.keys(productMap).filter(asin => asin.toLowerCase().includes(queryInput.toLowerCase()));
            // console.log(asinMatches);
            setFilteredData(Object.assign({}, ...asinMatches.map(asin => ({ [asin]: data[asin] }))));
        } else {
            // const productMatches = Array.from(Object.keys(reverseProductMap), product => product.toLowerCase().includes(queryInput.toLowerCase()));
            const productMatches = Object.keys(reverseProductMap).filter(product => product.toLowerCase().includes(queryInput.toLowerCase()));
            setFilteredData(Object.assign({}, ...productMatches.map(product => ({ [product]: data[reverseProductMap[product]] }))));
        }
        return;
    }
};

export function getData(setData, selectedBrand, setAsinsReady, setFilteredData, setProductMap, setReverseProductMap) {
    if (selectedBrand === 'Select Brand') {
        return;
    } else {
        var query = queryWriter(selectedBrand);
        var body = JSON.stringify({ query: query })
        setAsinsReady(false)
        fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var results = JSON.parse(json.results);
                const formattedData = formatData(results);
                const protoMap = Array.from(results, (item) => { return {[item['asin']]: item['short_title']}});
                const protoMapReverse = Array.from(results, (item) => { return {[item['short_title']]: item['asin']}});
                const newProductMap = Object.assign({}, ...protoMap);
                const newReverseProductMap = Object.assign({}, ...protoMapReverse);
                setProductMap(newProductMap);
                setReverseProductMap(newReverseProductMap);
                setData(formattedData);
                setFilteredData(formattedData);
                // var asinList = results.map(item => item["asin"])
                // setData(results)
                // setAsinsReady(true)
                // setAsins(asinList)
            })
    }; 
};

export function formatData(newData) {
    const asinList = newData.map(item => item["asin"]);
    const newAsinMap = Object.assign({}, ...asinList.map(asin => ({ [asin]: newData.filter(item => item["asin"] === asin) })));
    return newAsinMap
};

export function getBrands(brands, setBrands) {
    if (brands.length === 0) {
        var body = JSON.stringify({ query: brandsQuery})
        fetch('https://dev.growve.tools:8080/getProvidedQuery', { method: 'POST', mode: 'cors', body: body })
            .then(response => {
                return response.json()
            })
            .then(json => {
                var raw_results = JSON.parse(json.results)
                // console.log(raw_results)
                setBrands(raw_results[0].brands);
            });
            return;
    } else {
        return;
    }
    
};