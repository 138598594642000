export const xAxisConfig = (data) => {
    return {
        crossAxis: true,
        standalone: false,
        width: 640,
        height: 400,
        tickValues: data.dateTicks,
        tickFormat: (t) => `${new Date(t).toLocaleDateString('en-us', {month: 'short', day: 'numeric' })}\n${new Date(t).toLocaleDateString('en-us', {year: 'numeric' })}`,
        tickCount: data.dateTicks.length,
        padding: {top: 50, right: 50, bottom: 50, left: 50},
        style: { 
            tickLabels: { 
                fontSize: 10, 
                fontStyle: "bold", 
                color: "#95B1B9", 
                fill: "#95B1B9",
                // angle: -30
            }, 
            grid: {
                stroke: "#ffffff", 
                opacity: 0.2,
                strokeDasharray: "10, 5",
            }, 
            axis: { 
                stroke: "#63A60A", 
                strokeWidth: 2
            }, 
            axisLabel: {
                fontSize: 10, 
                fontWeight: "bold", 
                fill: "#95B1B9"
            }
        }};           
};

export const yAxisConfig = (data, getAxisString, columnIsCurrency) => {
    return {
        dependentAxis: true,
        standalone: false,
        height: 400,
        width: 640,
        orientation: 'left',
        tickValues: data.yTicks,
        tickFormat: (tick, idx) => getAxisString(data.yTicks[idx], columnIsCurrency),
        tickCount: data.yTicks.length,
        margin: {
            left: 100, 
            right: 100, 
            top: 100, 
            bottom: 100
        },
        padding: {
            left: 100, 
            right: 100, 
            top: 100, 
            bottom: 100
        },
        style: { 
            tickLabels: { 
                fontSize: 10, 
                fontStyle: "bold", 
                color: "#95B1B9", 
                fill: "#95B1B9",
            }, 
            axis: { 
                stroke: "#63A60A", 
                strokeWidth: 2
            }, 
            axisLabel: {
                fontSize: 10, 
                fontWeight: "bold", 
                fill: "#95B1B9",
                margin: {
                    left: 100}
            }, 
            grid: {
                stroke: "#ffffff", 
                opacity: 0.2,
                strokeDasharray: "10, 5",
            } 
        }
    }
};

export const legendConfig = (yOneLabel, yTwoLabel) => {
    return {
        x: 315,
        y: 0,
        style: {
            labels: {
                fill: "#95B1B9", 
                color: "#95B1B9"
            },
            border: {
                stroke: "transparent"
            }
        },
        data: [
            {
                name: yTwoLabel,
                symbol: {
                    fill: "#63A60A",
                    type: "circle"
                }
            },
            {
                name: yOneLabel,
                symbol: {
                    fill: "#95B1B9",
                    type: "circle"
                }
            }
        ]
    };

};